import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';

const ReelList = () => {
  const [reels, setReels] = useState([]);
  const [updatedReelUrl, setUpdatedReelUrl] = useState('');
  const [selectedReelId, setSelectedReelId] = useState(null);

  // Fetch the reels from the backend
  const fetchReels = async () => {
    try {
      const response = await fetch(SummaryApi.getAllReels.url, {
        method: SummaryApi.getAllReels.method,
      });
      const data = await response.json();

      if (Array.isArray(data.reels)) {
        setReels(data.reels);
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error('Error fetching reels:', error);
    }
  };

  useEffect(() => {
    fetchReels();
  }, []);

  // Delete a reel
  const deleteReel = async (reelId) => {
    try {
      const response = await fetch(`${SummaryApi.deleteReel.url}/${reelId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Option 1: Fetch the updated list of reels
        fetchReels();

        // Option 2: Remove the reel directly from state
        // setReels(reels.filter(reel => reel._id !== reelId));

        console.log("Reel deleted successfully");
      } else {
        console.error("Failed to delete reel");
      }
    } catch (error) {
      console.error("Error deleting reel:", error);
    }
  };

  // Update a reel
  const updateReel = async () => {
    if (!selectedReelId || !updatedReelUrl) {
      return; // Add some error handling
    }

    try {
      const response = await fetch(SummaryApi.updateReel.url, {
        method: SummaryApi.updateReel.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reelId: selectedReelId, reelUrl: updatedReelUrl }),
      });

      if (response.ok) {
        // Fetch updated reels after update
        fetchReels();
        setUpdatedReelUrl('');
        setSelectedReelId(null);
      } else {
        console.error("Failed to update reel");
      }
    } catch (error) {
      console.error("Error updating reel:", error);
    }
  };

  return (
    <div>
      <h2>Reel List</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {reels.map((reel) => (
          <li key={reel._id} style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
            <span>{reel.url}</span>
            <div>
              <button onClick={() => { setSelectedReelId(reel._id); setUpdatedReelUrl(reel.url); }}>Update</button>
              <button onClick={() => deleteReel(reel._id)} style={{ color: 'red' }}>Delete</button>
            </div>
          </li>
        ))}
      </ul>

      {/* Update Reel Modal */}
      {selectedReelId && (
        <div style={{ marginTop: '20px' }}>
          <input
            type="text"
            value={updatedReelUrl}
            onChange={(e) => setUpdatedReelUrl(e.target.value)}
            placeholder="Update Reel URL"
            style={{ width: '300px' }}
          />
          <button onClick={updateReel}>Confirm Update</button>
          <button onClick={() => { setSelectedReelId(null); setUpdatedReelUrl(''); }}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default ReelList;
