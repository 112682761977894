import React from 'react'
import CategoryList from '../components/CategoryList'
import Trust from '../components/Trust.js'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <CategoryList/>
      <BannerProduct/>

      <HorizontalCardProduct category={"Sandalwood"} heading={"Top's Sandalwood"}/>
      <HorizontalCardProduct category={"Kazima"} heading={"Popular's Kazima"}/>

      <VerticalCardProduct category={"Amber"} heading={"Amber"}/>
      <VerticalCardProduct category={"Oud"} heading={"Oud"}/>
      <VerticalCardProduct category={"Blue"} heading={"Blue"}/>
      <VerticalCardProduct category={"Rose"} heading={"Rose"}/>
      <VerticalCardProduct category={"misk"} heading={" Misk"}/>
      <VerticalCardProduct category={"Royal"} heading={"Royal"}/>
      <VerticalCardProduct category={"Adonis"} heading={"Adonis"}/>
      <VerticalCardProduct category={"Saffron"} heading={"Saffron"}/>

      <Trust/>

    </div>
  )
}

export default Home