const productCategory = [
    { id : 1, label : "Sandalwood", value : "Sandalwood"},
    { id : 2, label : "Rose", value : "Rose"},
    { id : 3, label : "misk", value : "misk"},
    { id : 4, label : "Amber", value : "Amber"},
    { id : 5, label : "Oud", value : "Oud"},
    { id : 6, label : "Firdaus", value : "Firdaus"},
    { id : 7, label : "Mukhalla", value : "Mukhalla"},
    { id : 8, label : "Adonis", value : "Adonis"},
    { id : 9, label : "Royal", value : "Royal"},
    { id : 10, label : "Saffron", value : "Saffron"},
    { id : 11, label : "Blue", value : "Blue"},
    { id : 12, label : "Kazima", value : "Kazima"},
]


export default productCategory