import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import quality from '../assest/quality.avif';
import long from '../assest/long.png';
import vary from '../assest/vary.jpg';
import vegan from '../assest/vegan.avif';
import customer from '../assest/customer.avif';

export default function MediaCard() {
  const theme = useTheme(); // To access theme breakpoints

  return (
    <>
      <h2 style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>Why Trust Us?</h2>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: 1,
          justifyItems: 'center',
          height: '5vh',
          marginTop: 4,
          alignItems: 'center',
          minHeight: '40vh',
          [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row on large screens
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row on medium screens
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row on small screens
          },
        }}
      >
        <Card sx={{ maxWidth: 120, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 120 }} image={quality} title="Premium Quality" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>Premium Quality</h1>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: 120, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 120 }} image={long} title="Long Lasting" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>Long Lasting</h1>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: 120, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 120 }} image={vary} title="Variety of Fragrances" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>Variety of Fragrances</h1>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: 110, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 110 }} image={vegan} title="100% Vegan" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>100% Vegan</h1>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: 120, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 120 }} image={customer} title="Customer Satisfaction" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>Customer Satisfaction</h1>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: 120, marginLeft: 3, marginRight: 3 }}>
          <CardMedia sx={{ height: 80, width: 120 }} image={quality} title="Premium Quality" />
          <CardContent>
            <h1 style={{ fontSize: '0.9rem' }}>Premium Quality</h1>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
