// const backendDomin = process.env.PORT

const SummaryApi = {
    signUP : {
        url : `/api/signup`,
        method : "post"
    },
    signIn : {
        url : `/api/signin`,
        method : "post"
    },
    current_user : {
        url : `/api/user-details`,
        method : "get"
    },
    logout_user : {
        url : `/api/userLogout`,
        method : 'get'
    },
    allUser : {
        url : `/api/all-user`,
        method : 'get'
    },
    updateUser : {
        url : `/api/update-user`,
        method : "post"
    },
    uploadProduct : {
        url : `/api/upload-product`,
        method : 'post'
    },
    allProduct : {
        url : `/api/get-product`,
        method : 'get'
    },
    updateProduct : {
        url : `/api/update-product`,
        method  : 'post'
    },
    categoryProduct : {
        url : `/api/get-categoryProduct`,
        method : 'get'
    },
    categoryWiseProduct : {
        url : `/api/category-product`,
        method : 'post'
    },
    productDetails : {
        url : `/api/product-details`,
        method : 'post'
    },
    addToCartProduct : {
        url : `/api/addtocart`,
        method : 'post'
    },
    addToCartProductCount : {
        url : `/api/countAddToCartProduct`,
        method : 'get'
    },
    addToCartProductView : {
        url : `/api/view-card-product`,
        method : 'get'
    },
    updateCartProduct : {
        url : `/api/update-cart-product`,
        method : 'post'
    },
    deleteCartProduct : {
        url : `/api/delete-cart-product`,
        method : 'post'
    },
    searchProduct : {
        url : `/api/search`,
        method : 'get'
    },
    filterProduct : {
        url : `/api/filter-product`,
        method : 'post'
    },

    // deleteProduct : {
    //     url : `/api/delete-product`,
    //     method : 'post'
    // },

    companyNames: {
        url: `/api/get-company`, // This is the GET route to fetch the company name
        method: 'get'
    },
    updateCompanyName: {
        url: `/api/update-company`, // This is the POST route to update the company name
        method: 'post'
    },

    
    addReel: {
        url: `/api/add-reel`,
        method: 'post',
      },
      getAllReels: {
        url: `/api/get-reel`,
        method: 'get',
      },
      updateReel: {
        url: `/api/update-reel`,
        method: 'put',
      },
      deleteReel: {
        url: `/api/delete-reel`,
        method: 'DELETE',
      },


      createPayment: {
        url: `/api/create-payment`,
        method: 'post',
      },
      getPayment: {
        url: `/api/get-payment`,
        method: 'get',
      },
      updatePayment: {
        url: `/api/update-payment`,
        method: 'PATCH',
      },
    //   deletePayment: {
    //     url: `/api/delete-payment`,
    //     method: 'DELETE',
    //   },

    //   addImage: {
    //     url: `/api/add-image`,
    //     method: 'post',
    //   },

    //   getImage: {
    //     url: `/api/get-image`,
    //     method: 'get',
    //   },
}


export default SummaryApi