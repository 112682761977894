import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './StockTicker.css';
import SummaryApi from '../common';

const Announce = () => {
  const [companyName, setCompanyName] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  // Fetch company name from backend
  useEffect(() => {
    fetch(SummaryApi.companyNames.url)
      .then((response) => response.json())
      .then((data) => setCompanyName(data.companyName))
      .catch((error) => console.error('Error fetching company name:', error));
  }, []);

  // Monitor scroll to hide ticker after a certain point
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle close functionality
  const handleClose = () => setIsVisible(false);

  return (
    isVisible && (
      <div className="stock-ticker">
        <ul>
          <li className="minus">
            <span className="company">{companyName}</span>
          </li>
        </ul>
        <div className="close-icon" onClick={handleClose}>
          {/* <AiOutlineClose /> */}
        </div>
      </div>
    )
  );
};

export default Announce;
