import React, { useContext, useState } from 'react'
import Logo from './ll.png';
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify'
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';

const Header = () => {
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const context = useContext(Context)
  const navigate = useNavigate()
  const searchInput = useLocation()
  const URLSearch = new URLSearchParams(searchInput?.search)
  const searchQuery = URLSearch.getAll("q")
  const [search, setSearch] = useState(searchQuery)

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    })

    const data = await fetchData.json()

    if (data.success) {
      toast.success(data.message)
      dispatch(setUserDetails(null))
      navigate("/")
    }

    if (data.error) {
      toast.error(data.message)
    }

  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)

    if (value) {
      navigate(`/search?q=${value}`)
    } else {
      navigate("/search")
    }
  }
  return (
    <header className='h-20 shadow-md bg-black fixed w-full z-40'>
      <div className=' h-20 container mx-auto flex items-center px-1 justify-between'>
        <div className=''>
          <Link to={"/"}>
            <img src={Logo} alt="Company Logo" style={{ width: '100px', height: '65px' }} />
          </Link>
        </div>

        <div className='flex items-center justify-between max-w-sm rounded-full border-2 pl-1 ml-2'>
          {/* Mobile Screen Search Input */}
          <div className='lg:hidden flex items-center w-full'> {/* Adjust width here */}
            <input
              type='text'
              placeholder='Search Products...'
              className='w-full outline-none text-white bg-transparent'
              onChange={handleSearch}
              value={search}
            />
            <div className='text-lg min-w-[25px] h-8 bg-red-600 flex items-center justify-center rounded-r-full text-white'>
              <GrSearch />
            </div>
          </div>
        </div>


        <div className=''>
  {/* Large Screen Search Input */}
  <div className='hidden lg:flex items-center w-full border-2 rounded-full justify-between max-w-sm'>
    <input
      type='text'
      placeholder='search product here...'
      className='w-full outline-none bg-transparent text-white px-6 py-1 rounded-l-full'
      onChange={handleSearch}
      value={search}
    />
    <div className='text-lg min-w-[50px] h-8 bg-red-600 flex items-center justify-center rounded-r-full text-white'>
      <GrSearch />
    </div>
  </div>
</div>



        <div className='flex items-center gap-3'>

          <div className='relative flex justify-center'>

            {
              user?._id && (
                <div className='text-3xl cursor-pointer relative flex justify-center ml-4' onClick={() => setMenuDisplay(preve => !preve)}>
                  {
                    user?.profilePic ? (
                      <img src={user?.profilePic} className='w-20 h-8 rounded-full' alt={user?.name} />
                    ) : (
                      <FaRegCircleUser className='ml-2' />
                    )
                  }
                </div>
              )
            }

            {/* {
                    menuDisplay && (
                      <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded' >
                        <nav>
                          {
                            user?.role === ROLE.ADMIN && (
                              <Link to={"/admin-panel/all-products"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={()=>setMenuDisplay(preve => !preve)}>Admin Panel</Link>
                            )
                          }
                         
                        </nav>
                      </div>
                    )
                  } */}

            {
              menuDisplay && (
                <div className="absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded">
                  <nav>
                    {
                      user?.role === ROLE.ADMIN && (
                        <Link
                          to="/admin-panel/all-products"
                          className="whitespace-nowrap hover:bg-slate-100 p-2"
                          onClick={() => setMenuDisplay(prev => !prev)}
                        >
                          Admin Panel
                        </Link>
                      )
                    }
                  </nav>
                </div>
              )
            }
          </div>

          {
            user?._id && (
              <Link to={"/cart"} className='text-2xl relative text-white'>
                <span className='text-white'><FaShoppingCart /></span>

                <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-3 -right-3'>
                  <p className='text-sm text-white'>{context?.cartProductCount}</p>
                </div>
              </Link>
            )
          }


          <div>
            {
              user?._id ? (
                <button onClick={handleLogout} className='px-1 py-1 rounded-full text-white bg-red-600'>
                  <span className='flex items-center justify-center w-6 h-6'> {/* Adjust width and height here */}
                    <FaSignOutAlt />
                  </span>
                </button>

              ) : (
                <Link to={"/login"} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 flex items-center justify-center'>
                  <span className='flex items-center justify-center w-3 h-7'> {/* Adjust width and height here */}
                    <FaUser />
                  </span>
                </Link>
              )
            }
          </div>


        </div>

      </div>
    </header>
  )
}

export default Header