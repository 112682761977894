import React, { useEffect, useState } from 'react';
import UploadProduct from '../components/UploadProduct';
import SummaryApi from '../common';
import AdminProductCard from '../components/AdminProductCard';
import ReelList from '../components/ReelList';
import { toast } from 'react-toastify';


const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [reelUrl, setReelUrl] = useState('');

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.allProduct.url);
    const dataResponse = await response.json();

    console.log('product data', dataResponse);

    setAllProduct(dataResponse?.data || []);
  };

  // Fetch company name from backend
  useEffect(() => {
    fetch(SummaryApi.companyNames.url)
      .then((response) => response.json())
      .then((data) => {
        setCompanyName(data.companyName);
      })
      .catch((error) => {
        console.error('Error fetching company name:', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    fetch(SummaryApi.updateCompanyName.url, {
      method: SummaryApi.updateCompanyName.method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ companyName: newCompanyName }),
    })
      .then((response) => response.json())
      .then(() => {
        setCompanyName(newCompanyName);
        setNewCompanyName('');
        fetchAllProduct(); // Fetch updated products
      })
      .catch((error) => {
        console.error('Error updating company name:', error);
      });
  };

  const handleSubmited = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(SummaryApi.addReel.url, {
        method: SummaryApi.addReel.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reelUrl }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('Reel added successfully!');
        setReelUrl(''); // Clear the input
      } else {
        toast.error(data.message || 'Error adding reel');
      }
    } catch (error) {
      console.error('Error adding reel:', error);
      toast.error('Error adding reel');
    }
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <div>
      <div className='bg-white py-2 px-4 flex justify-between items-center' style={{marginTop: '60px' }}>
        <h2 className='font-bold text-lg'>All Product</h2>
        <button
          className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full'
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/** Form to update company name */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={newCompanyName}
          onChange={(e) => setNewCompanyName(e.target.value)}
          placeholder="Enter new company name"
        />
        <button type="submit">Update Name</button>
      </form>

      <form onSubmit={handleSubmited}>
      <input
        type="text"
        value={reelUrl}
        onChange={(e) => setReelUrl(e.target.value)}
        placeholder="Enter Instagram Reel URL"
        required
      />
      <button type="submit">Add Reel</button>
    </form>

    <ReelList/>


      {/** All product */}
      <div className='flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
        {allProduct.map((product, index) => {
          return <AdminProductCard data={product} key={index + 'allProduct'} fetchdata={fetchAllProduct} />;
        })}
      </div>


      {/** Upload product component */}
      {openUploadProduct && <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchData={fetchAllProduct} />}
    </div>
  );
};

export default AllProducts;







// import React, { useEffect, useState } from 'react'
// import UploadProduct from '../components/UploadProduct'
// import SummaryApi from '../common'
// import AdminProductCard from '../components/AdminProductCard'

// const AllProducts = () => {
//   const [openUploadProduct,setOpenUploadProduct] = useState(false)
//   const [allProduct,setAllProduct] = useState([])

//   const fetchAllProduct = async() =>{
//     const response = await fetch(SummaryApi.allProduct.url)
//     const dataResponse = await response.json()

//     console.log("product data",dataResponse)

//     setAllProduct(dataResponse?.data || [])
//   }

//   useEffect(()=>{
//     fetchAllProduct()
//   },[])
  
//   return (
//     <div>
//         <div className='bg-white py-2 px-4 flex justify-between items-center'>
//             <h2 className='font-bold text-lg'>All Product</h2>
//             <button  className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full ' onClick={()=>setOpenUploadProduct(true)}>Upload Product</button>
//         </div>

//         {/**all product */}
//         <div className='flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
//           {
//             allProduct.map((product,index)=>{
//               return(
//                 <AdminProductCard data={product} key={index+"allProduct"} fetchdata={fetchAllProduct}/>
                
//               )
//             })
//           }
//         </div>





//         {/**upload prouct component */}
//         {
//           openUploadProduct && (
//             <UploadProduct onClose={()=>setOpenUploadProduct(false)} fetchData={fetchAllProduct}/>
//           )
//         }
      

//     </div>
//   )
// }

// export default AllProducts