import React, { useContext, useEffect, useState } from 'react';
import SummaryApi from '../common';
import Context from '../context';
import displayINRCurrency from '../helpers/displayCurrency';
import { MdDelete } from "react-icons/md";
import { toast } from 'react-toastify'


const Cart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [mobile, setMobile] = useState('');
    const context = useContext(Context);
    const loadingCart = new Array(4).fill(null);

    const fetchData = async () => {
        const response = await fetch(SummaryApi.addToCartProductView.url, {
            method: SummaryApi.addToCartProductView.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
        });

        const responseData = await response.json();

        console.log(responseData);

        if (responseData.success) {
            setData(responseData.data);
        }
    };

    const handleLoading = async () => {
        await fetchData();
    };

    useEffect(() => {
        setLoading(true);
        handleLoading();
        setLoading(false);
    }, []);

    const increaseQty = async (id, qty) => {
        const response = await fetch(SummaryApi.updateCartProduct.url, {
            method: SummaryApi.updateCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify({
                _id: id,
                quantity: qty + 1
            })
        });

        const responseData = await response.json();

        if (responseData.success) {
            fetchData();
        }
    };

    const decreaseQty = async (id, qty) => {
        if (qty >= 2) {
            const response = await fetch(SummaryApi.updateCartProduct.url, {
                method: SummaryApi.updateCartProduct.method,
                credentials: 'include',
                headers: {
                    "content-type": 'application/json'
                },
                body: JSON.stringify({
                    _id: id,
                    quantity: qty - 1
                })
            });

            const responseData = await response.json();

            if (responseData.success) {
                fetchData();
            }
        }
    };

    const deleteCartProduct = async (id) => {
        const response = await fetch(SummaryApi.deleteCartProduct.url, {
            method: SummaryApi.deleteCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify({
                _id: id,
            })
        });

        const responseData = await response.json();

        if (responseData.success) {
            fetchData();
            context.fetchUserAddToCart();
        }
    };

    const handleCashOnDelivery = async () => {
        const paymentDetails = {
            fullname,
            address,
            city,
            mobile,
            paymentMethod: 'Cash on Delivery',
            totalQty,
            totalPrice,
            products: data.map(product => ({
                productId: product.productId._id,
                productName: product.productId.productName,
                quantity: product.quantity,
                selectedvarient: product.selectedvarient,
                price: product.selectedvarient === 6
                    ? product.productId.sixsellingPrice
                    : product.productId.twelvesellingPrice,
            })),
        };

        const response = await fetch(SummaryApi.createPayment.url, {
            method: SummaryApi.createPayment.method,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentDetails),
        });

        const responseData = await response.json();

        if (responseData.success) {
            toast.success("Cash on Delivery order placed successfully!");
        } else {
            toast.error("Failed to place COD order.");
        }

    };



    // Calculate total quantity and total price based on selectedvarient
    const totalQty = data.reduce((prev, curr) => prev + curr.quantity, 0);
    const totalPrice = data.reduce((prev, curr) => {
        const price =
            curr.selectedvarient === 6
                ? curr.productId.sixsellingPrice
                : curr.productId.twelvesellingPrice;
        return prev + curr.quantity * price;
    }, 0);

    return (
        <div className='container mx-auto p-4'>
            <div className='text-center text-lg mt-16'>
                {data.length === 0 && !loading && (
                    <p className='bg-white py-5'>No Data</p>
                )}
            </div>

            <div className='flex flex-col lg:flex-row gap-10 lg:justify-between'>
                <div className='w-full max-w-3xl'>
                    {loading ? (
                        loadingCart.map((_, index) => (
                            <div key={index} className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded'></div>
                        ))
                    ) : (
                        data.map((product) => {
                            const price =
                                product.selectedvarient === 6
                                    ? product.productId.sixsellingPrice
                                    : product.productId.twelvesellingPrice;
                            return (
                                <div key={product?._id} className='w-full bg-white h-32 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]'>
                                    <div className='w-32 h-32 bg-slate-200'>
                                        <img src={product?.productId?.productImage[0]} className='w-full h-full object-scale-down mix-blend-multiply' />
                                    </div>
                                    <div className='px-4 py-2 relative'>
                                        <div className='absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => deleteCartProduct(product?._id)}>
                                            <MdDelete />
                                        </div>
                                        <h2 className='text-lg lg:text-xl text-ellipsis line-clamp-1'>{product?.productId?.productName}</h2>
                                        <p className='capitalize text-slate-500'>{product?.productId.category}</p>
                                        <div className='flex items-center justify-between'>
                                            <p className='text-red-600 font-medium text-lg'>{displayINRCurrency(price)}</p>
                                            <p className='text-slate-600 font-semibold text-lg'>{displayINRCurrency(price * product?.quantity)}</p>
                                        </div>
                                        <div className='flex items-center gap-3 mt-1'>
                                            <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded' onClick={() => decreaseQty(product?._id, product?.quantity)}>-</button>
                                            <span>{product?.quantity}</span>
                                            <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded' onClick={() => increaseQty(product?._id, product?.quantity)}>+</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>

                <div className='w-full max-w-sm'>
                    {loading ? (
                        <div className='h-36 bg-slate-200 border border-slate-300 animate-pulse'></div>
                    ) : (
                        <div className='h-36 bg-white'>
                            <h2 className='text-white bg-red-600 px-4 py-1'>Summary</h2>
                            <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                <p>Quantity</p>
                                <p>{totalQty}</p>
                            </div>
                            <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                <p>Total Price</p>
                                <p>₹ {totalPrice}</p>
                            </div>
                            <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                <p>Delivery Within</p>
                                <p>24 hrs</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <form className='bg-white p-4 mt-4 shadow-md rounded-lg grid gap-4'>
                <h2 className='text-lg font-semibold text-center'>Shipping Information</h2>
                <div className='grid md:grid-cols-2 gap-4'>
                    <input
                        type='text'
                        className='p-2 border border-gray-300 rounded w-full'
                        placeholder='Full Name'
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                    />
                    <input
                        type='text'
                        className='p-2 border border-gray-300 rounded w-full'
                        placeholder='House number & street name'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <input
                        type='text'
                        className='p-2 border border-gray-300 rounded w-full'
                        placeholder='Town / City'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <input
                        type='number'
                        className='p-2 border border-gray-300 rounded w-full'
                        placeholder='Mobile Number'
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    />
                </div>


                <div className='flex justify-between gap-4 mt-2'>
                    <button
                        type="button"
                        className="flex-1 bg-green-600 text-white py-1 px-2 rounded text-sm hover:bg-green-700"
                        onClick={handleCashOnDelivery}
                    >
                        Cash On Delivery
                    </button>

                    <button
                        type='button'
                        className='flex-1 bg-blue-600 text-white py-1 px-2 rounded text-sm hover:bg-blue-700'
                        onClick={() => console.log("Online Payment Selected")}
                    >
                        Online Payment
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Cart;

















// import React, { useContext, useEffect, useState } from 'react';
// import SummaryApi from '../common';
// import Context from '../context';
// import displayINRCurrency from '../helpers/displayCurrency';
// import { MdDelete } from "react-icons/md";

// const Cart = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [address, setAddress] = useState('');
//     const [city, setCity] = useState('');
//     const [mobile, setMobile] = useState('');
//     const context = useContext(Context);
//     const loadingCart = new Array(4).fill(null);

//     const fetchData = async () => {
//         const response = await fetch(SummaryApi.addToCartProductView.url, {
//             method: SummaryApi.addToCartProductView.method,
//             credentials: 'include',
//             headers: {
//                 "content-type": 'application/json'
//             },
//         });

//         const responseData = await response.json();

//         if (responseData.success) {
//             setData(responseData.data);
//         }
//     };

//     const handleLoading = async () => {
//         await fetchData();
//     };

//     useEffect(() => {
//         setLoading(true);
//         handleLoading();
//         setLoading(false);
//     }, []);

//     const increaseQty = async (id, qty) => {
//         const response = await fetch(SummaryApi.updateCartProduct.url, {
//             method: SummaryApi.updateCartProduct.method,
//             credentials: 'include',
//             headers: {
//                 "content-type": 'application/json'
//             },
//             body: JSON.stringify({
//                 _id: id,
//                 quantity: qty + 1
//             })
//         });

//         const responseData = await response.json();

//         if (responseData.success) {
//             fetchData();
//         }
//     };

//     const decreaseQty = async (id, qty) => {
//         if (qty >= 2) {
//             const response = await fetch(SummaryApi.updateCartProduct.url, {
//                 method: SummaryApi.updateCartProduct.method,
//                 credentials: 'include',
//                 headers: {
//                     "content-type": 'application/json'
//                 },
//                 body: JSON.stringify({
//                     _id: id,
//                     quantity: qty - 1
//                 })
//             });

//             const responseData = await response.json();

//             if (responseData.success) {
//                 fetchData();
//             }
//         }
//     };

//     const deleteCartProduct = async (id) => {
//         const response = await fetch(SummaryApi.deleteCartProduct.url, {
//             method: SummaryApi.deleteCartProduct.method,
//             credentials: 'include',
//             headers: {
//                 "content-type": 'application/json'
//             },
//             body: JSON.stringify({
//                 _id: id,
//             })
//         });

//         const responseData = await response.json();

//         if (responseData.success) {
//             fetchData();
//             context.fetchUserAddToCart();
//         }
//     };

//     const handleFormSubmit = (e) => {
//         e.preventDefault();
//         // Handle form submission logic here
//         console.log({ address, city, mobile });
//     };

//     const totalQty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
//     const totalPrice = data.reduce((prev, curr) => prev + (curr.quantity * curr?.productId?.twelvesellingPrice), 0);

//     return (
//         <div className='container mx-auto p-4'>
//             <div className='text-center text-lg mt-16'>
//                 {data.length === 0 && !loading && (
//                     <p className='bg-white py-5'>No Data</p>
//                 )}
//             </div>

//             <div className='flex flex-col lg:flex-row gap-10 lg:justify-between'>
//                 <div className='w-full max-w-3xl'>
//                     {loading ? (
//                         loadingCart.map((_, index) => (
//                             <div key={index} className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded'></div>
//                         ))
//                     ) : (
//                         data.map((product) => (
//                             <div key={product?._id} className='w-full bg-white h-32 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]'>
//                                 <div className='w-32 h-32 bg-slate-200'>
//                                     <img src={product?.productId?.productImage[0]} className='w-full h-full object-scale-down mix-blend-multiply' />
//                                 </div>
//                                 <div className='px-4 py-2 relative'>
//                                     <div className='absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => deleteCartProduct(product?._id)}>
//                                         <MdDelete />
//                                     </div>
//                                     <h2 className='text-lg lg:text-xl text-ellipsis line-clamp-1'>{product?.productId?.productName}</h2>
//                                     <p className='capitalize text-slate-500'>{product?.productId.category}</p>
//                                     <div className='flex items-center justify-between'>
//                                         <p className='text-red-600 font-medium text-lg'>{displayINRCurrency(product?.productId?.twelvesellingPrice)}</p>
//                                         <p className='text-slate-600 font-semibold text-lg'>{displayINRCurrency(product?.productId?.twelvesellingPrice * product?.quantity)}</p>
//                                     </div>
//                                     <div className='flex items-center gap-3 mt-1'>
//                                         <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded' onClick={() => decreaseQty(product?._id, product?.quantity)}>-</button>
//                                         <span>{product?.quantity}</span>
//                                         <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded' onClick={() => increaseQty(product?._id, product?.quantity)}>+</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))
//                     )}
//                 </div>

//                 <div className='w-full max-w-sm'>
//                     {loading ? (
//                         <div className='h-36 bg-slate-200 border border-slate-300 animate-pulse'></div>
//                     ) : (
//                         <div className='h-36 bg-white'>
//                             <h2 className='text-white bg-red-600 px-4 py-1'>Summary</h2>
//                             <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
//                                 <p>Quantity</p>
//                                 <p>{totalQty}</p>
//                             </div>
//                             <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
//                                 <p>Total Price</p>
//                                 <p>₹ {totalPrice}</p>
//                             </div>
//                             <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
//                                 <p>Delivery Within</p>
//                                 <p>24 hrs</p>
//                             </div>
//                             {/* <button className='bg-blue-600 p-2 text-white w-full mt-2'>Proceed</button> */}
//                         </div>
//                     )}
//                 </div>
//             </div>

//             {/* Address Form Below Proceed Button */}
//             <form onSubmit={handleFormSubmit} className='bg-white p-4 mt-4 shadow-md rounded-lg grid gap-4'>
//                 <h2 className='text-lg font-semibold text-center'>Shipping Information</h2>
//                 <div className='grid md:grid-cols-2 gap-4'>
//                     <input
//                         type='text'
//                         className='p-2 border border-gray-300 rounded w-full'
//                         placeholder='Address'
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                     />
//                     <input
//                         type='text'
//                         className='p-2 border border-gray-300 rounded w-full'
//                         placeholder='City'
//                         value={city}
//                         onChange={(e) => setCity(e.target.value)}
//                     />
//                 </div>
//                 <input
//                     type='number'
//                     className='p-2 border border-gray-300 rounded w-full'
//                     placeholder='Mobile Number'
//                     value={mobile}
//                     onChange={(e) => setMobile(e.target.value)}
//                 />

//                 <div className='flex justify-between gap-4 mt-2'>
//                     <button
//                         type='button'
//                         className='flex-1 bg-green-600 text-white py-1 px-2 rounded text-sm hover:bg-green-700'
//                         onClick={() => console.log("Cash Payment Selected")}
//                     >
//                         Cash On Delivery
//                     </button>
//                     <button
//                         type='button'
//                         className='flex-1 bg-blue-600 text-white py-1 px-2 rounded text-sm hover:bg-blue-700'
//                         onClick={() => console.log("Online Payment Selected")}
//                     >
//                         Online Payment
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default Cart;