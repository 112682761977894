import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { MdModeEdit } from "react-icons/md";

const Payment = () => {
    const [allPayments, setAllPayments] = useState([]);

    const fetchAllPayments = async () => {
        const response = await fetch(SummaryApi.getPayment.url, {
            method: SummaryApi.getPayment.method,
            credentials: 'include',
        });

        const data = await response.json();

        if (data.success) {
            setAllPayments(data.data);
        } else {
            toast.error(data.message);
        }
    };

    useEffect(() => {
        fetchAllPayments();
    }, []);


    const handleStatusChange = async (id, newStatus) => {
        try {
            const response = await fetch(`${SummaryApi.updatePayment.url}/${id}`, {
                method:SummaryApi.updatePayment.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
            const result = await response.json();
            if (result.success) {
                toast.success('Status updated successfully');
                fetchAllPayments(); // Refresh payment data
            } else {
                toast.success(result.message || 'Status updated successfully', {
                    autoClose: 10000, // 3000ms = 3 seconds
                  });
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('An error occurred while updating the status');
        }
    };
    
    
    
    

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
    };

    const thTdStyle = {
        border: '1px solid black',
        padding: '6px',
        textAlign: 'left',
    };

    const headerStyle = {
        ...thTdStyle,
        backgroundColor: 'black',
        color: 'white',
    };

    const radioContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
    };

    const labelStyle = {
        marginLeft: '8px',
    };

    return (
        <div className='bg-white pb-4'>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={headerStyle}>Sr.</th>
                        <th style={headerStyle}>Full Name</th>
                        <th style={headerStyle}>Address</th>
                        <th style={headerStyle}>City</th>
                        <th style={headerStyle}>Mobile</th>
                        <th style={headerStyle}>Payment Method</th>
                        <th style={headerStyle}>Product Name & ml</th>
                        <th style={headerStyle}>Total Price</th>
                        <th style={headerStyle}>Total Qty</th>
                        <th style={headerStyle}>Action</th>
                    </tr>
                </thead>
                <tbody>
    {allPayments.map((payment, index) => (
        <tr key={payment._id}>
            <td style={thTdStyle}>{index + 1}</td>
            <td style={thTdStyle}>{payment.fullname}</td>
            <td style={thTdStyle}>{payment.address}</td>
            <td style={thTdStyle}>{payment.city}</td>
            <td style={thTdStyle}>{payment.mobile}</td>
            <td style={thTdStyle}>{payment.paymentMethod}</td>
            <td style={thTdStyle}>
                {payment.products.map(product => (
                    <div key={product.productId}>
                        {product.productName} ({product.selectedvarient}-ml {product.quantity}-qty)
                    </div>
                ))}
            </td>
            <td style={thTdStyle}>{payment.totalPrice}</td>
            <td style={thTdStyle}>{payment.totalQty}</td>
            <td style={thTdStyle}>
                <div style={radioContainerStyle}>
                    <input
                        type="radio"
                        id={`complete-${payment._id}`}
                        name={`status-${payment._id}`}
                        value="Complete"
                        checked={payment.status === 'Complete'}
                        onChange={() => handleStatusChange(payment._id, 'Complete')}
                    />
                    <label htmlFor={`complete-${payment._id}`} style={labelStyle}>Completed</label>
                </div>
                <div style={radioContainerStyle}>
                    <input
                        type="radio"
                        id={`incomplete-${payment._id}`}
                        name={`status-${payment._id}`}
                        value="Incomplete"
                        checked={payment.status === 'Incomplete'}
                        onChange={() => handleStatusChange(payment._id, 'Incomplete')}
                    />
                    <label htmlFor={`incomplete-${payment._id}`} style={labelStyle}>Incompleted</label>
                </div>
            </td>
        </tr>
    ))}
</tbody>

            </table>
        </div>
    );
};

export default Payment;
