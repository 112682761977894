import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';

const Insta = () => {
  const [reels, setReels] = useState([]);

  const fetchReels = async () => {
    try {
      const response = await fetch(SummaryApi.getAllReels.url, {
        method: SummaryApi.getAllReels.method,
      });
      const data = await response.json();

      // Check if data contains an array or needs to extract reels
      if (Array.isArray(data)) {
        setReels(data); // data is an array
      } else if (Array.isArray(data.reels)) {
        setReels(data.reels); // data contains reels array
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching reels:', error);
    }
  };

  // Load Instagram embed script after the reels are fetched
  useEffect(() => {
    fetchReels();
  }, []);

  // Re-initialize Instagram embeds after each reel is rendered
  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    } else {
      const script = document.createElement('script');
      script.src = "https://www.instagram.com/embed.js";
      script.async = true;
      document.body.appendChild(script);

      // Cleanup script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [reels]); // Re-run when reels state changes

  return (
    <div>
     <h1 style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '5vh',
  marginTop: 40,
  textAlign: 'center',
  fontWeight: 'bold'
}}>
  Aalishan Perfumes Instagram Reels
</h1>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {Array.isArray(reels) && reels.length > 0 ? (
          reels.map((reel, index) => (
            <blockquote
              key={index}
              className="instagram-media"
              data-instgrm-permalink={reel.url.endsWith('/') ? reel.url : `${reel.url}/`} // Ensure URL ends with '/'
              data-instgrm-version="14"
              style={{
                width: '100%',
                maxWidth: '340px',  // Adjusted to 340px for better centering
                margin: '20px auto', // Centering using margin auto
                border: 'none',
                padding: '0', // Corrected typo from 'paddingr' to 'padding'
                overflow: 'hidden',
              }}
            >
              <a href={reel.url} target="_blank" rel="noopener noreferrer">Watch on Instagram</a>
            </blockquote>
          ))
        ) : (
          <p>No reels available</p>
        )}
      </div>
    </div>
  );
};

export default Insta;
